
  
.md-editor-fullscreen .md-editor-toolbar {
    padding: 50px 5px 5px 5px;
    border-radius: 3px 3px 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    align-items: center;
} 
.min-400{
    min-width: 400px;
    
}
.view-post{
    width:600px ;
}
@media screen and (max-width: 400px){
    .min-400{
        width: 100%;
    }
}

@media screen and (max-width: 600px){
    .view-post{
        width: 100%;
    }
}