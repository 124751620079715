body{
   
    width: 100%;
    
}

* { padding: 0; margin: 0; }
html, body {
    min-height: 100% !important;
    height: 100%;
}
.hover-white:hover{
    color: white;
}

